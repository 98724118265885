import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./providers/Router";

// react snap
// import { hydrate, render } from "react-dom";

// end react snap

import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import "./theme/styles.scss";

import "@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/group-ui/assets/themes/vwgroup/vwgroup.css";
import { defineCustomElements } from "@group-ui/group-ui-react/node_modules/@group-ui/group-ui/dist/components";
void defineCustomElements();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <Router />
  </ChakraProvider>,
);

// react snap
// const root = document.getElementById("root");
// if (root.hasChildNodes()) {
//     hydrate(<ChakraProvider theme={theme}><Router/></ChakraProvider>, root);
// }else {
//     render(<ChakraProvider theme={theme}><Router/></ChakraProvider>, root);
// }

// tracking
// fleet brand forms links
// fleet form
