import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import Error from "../pages/Error";
import App from "../App";
import Home from "../pages/Home";
import Group from "../pages/Group";
import Brands from "../pages/Brands";
import Careers from "../pages/Careers";
import News from "../pages/News";

import Contact from "../pages/Contact";
import About from "../pages/group/About";
import Team from "../pages/group/Team";
import Strategy from "../pages/group/Strategy";
import Integrity from "../pages/group/Integrity";
import Conduct from "../pages/group/Conduct";
import Whistleblower from "../pages/group/Whistleblower";
import HumanRights from "../pages/group/HumanRights";
import Fleet from "../pages/group/Fleet";

import WorkingWithUs from "../pages/WorkingWithUs";

import Template from "../pages/Template";

// routes
const generateRoutes = (locale) => ({
  path: `/${locale}`,
  element: <App />,
  errorElement: <Error />,
  children: [
    {
      index: true,
      element: <Home />,
    },
    {
      path: "group",
      element: <Group />,
    },
    {
      path: "group/about",
      element: <About />,
    },
    {
      path: "group/team",
      element: <Team />,
    },
    {
      path: "group/strategy",
      element: <Strategy />,
    },
    {
      path: "group/integrity",
      element: <Integrity />,
    },
    {
      path: "group/conduct",
      element: <Conduct />,
    },
    {
      path: "group/whistleblower",
      element: <Whistleblower />,
    },
    {
      path: "group/human-rights",
      element: <HumanRights />,
    },
    {
      path: "group/fleet",
      element: <Fleet />,
    },
    {
      path: "brands",
      element: <Brands />,
    },
    {
      path: "careers",
      element: <Careers />,
    },
    {
      path: "careers/working-with-us",
      element: <WorkingWithUs />,
    },
    {
      path: "news",
      element: <News />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "template",
      element: <Template />,
    },
  ],
});

// redirects
const generateRedirects = () => [
  { path: "/", element: <Navigate to="/en" replace /> },
  { path: "/group", element: <Navigate to="/en/group" replace /> },
  { path: "/group/about", element: <Navigate to="/en/group/about" replace /> },
  { path: "/group/team", element: <Navigate to="/en/group/team" replace /> },
  { path: "/group/strategy", element: <Navigate to="/en/group/strategy" replace /> },
  { path: "/group/integrity", element: <Navigate to="/en/group/integrity" replace /> },
  { path: "/group/conduct", element: <Navigate to="/en/group/conduct" replace /> },
  { path: "/group/whistleblower", element: <Navigate to="/en/group/whistleblower" replace /> },
  { path: "/group/human-rights", element: <Navigate to="/en/group/human-rights" replace /> },
  { path: "/group/fleet", element: <Navigate to="/en/group/fleet" replace /> },
  { path: "/brands", element: <Navigate to="/en/brands" replace /> },
  { path: "/careers", element: <Navigate to="/en/careers" replace /> },
  { path: "/careers/working-with-us", element: <Navigate to="/en/careers/working-with-us" replace /> },
  { path: "/news", element: <Navigate to="/en/news" replace /> },
  { path: "/contact", element: <Navigate to="/en/contact" replace /> },
  { path: "/template", element: <Navigate to="/en/template" replace /> },
];

const router = createBrowserRouter([...generateRedirects(), generateRoutes("en"), generateRoutes("ar")]);

function Provider() {
  return <RouterProvider router={router} />;
}

export default Provider;
