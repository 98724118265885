import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import useArabic from "../../hooks/useArabic";
import useText from "../../hooks/useText";
import parse from "html-react-parser";

export default function Page() {
  const arabic = useArabic();
  const text = useText();
  return (
    <div className="page">
      {/* banner */}
      <div className="ui-banner">
        <div className="media">
          <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Mountains_darkgreen.mp4`} autoPlay muted loop playsInline />
        </div>
        <div className="ui-inner">
          <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>
            {parse(text.conduct_banner_headline)}
          </GroupuiHeadline>
          <GroupuiText>{text.conduct_banner_text}</GroupuiText>
        </div>
      </div>
      {/* dual teaser */}
      <div className="ui-content-teaser">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.preface_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/conduct.jpg`} alt="" />
              </div>
            </div>
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.our_code_of_conduct}
              </GroupuiHeadline>
              <GroupuiText>
                {text.code_of_conduct_text}
                <br />
                <br />
                <b>{text.download_prompt}</b>
              </GroupuiText>
              <div className="btns">
                <a
                  href="https://www.volkswagen-group.com/en/publications/more/the-code-of-conduct-of-the-volkswagen-group-1882"
                  target="_blank"
                  rel="noreferrer"
                  className="cta"
                >
                  {text.download_cta}
                </a>
                <a
                  href="https://www.volkswagen-group.com/en/publications/more/code-of-conduct-for-business-partner-1885"
                  target="_blank"
                  rel="noreferrer"
                  className="cta"
                >
                  {text.download_business_partners_cta}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* dual teaser */}
      <div className="ui-content-teaser ui-deep-blue-bg">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.integrity_management_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.integrity_management_quote}
              </GroupuiHeadline>
              <GroupuiText>{text.integrity_management_text}</GroupuiText>
            </div>
          </div>
        </div>
      </div>

      <div className="ui-content-teaser">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.whistleblower_system_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/gradient3.jpg`} alt="" />
              </div>
            </div>
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.integrity_at_its_core}
              </GroupuiHeadline>
              <GroupuiText>{text.whistleblower_system_text}</GroupuiText>
              <a href={`/${arabic ? "ar" : "en"}/group/whistleblower`} className="cta">
                {text.learn_more_cta}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
