import useBreaks from "../hooks/useBreaks";

export default function Component({ img, video, title, body, dim, large, cta, full }) {
  const mq = useBreaks();

  return (
    <div className={`banner ${dim ? "dim" : ""} ${large ? "large" : ""} ${full ? "full" : ""}`}>
      {video ? <video src={video} autoPlay muted loop playsInline /> : <img src={img} alt={title} />}
      {(title || body) && (
        <div className="video-msg">
          <div className="inner">
            {title && <h1 className="txt-white">{title}</h1>}
            {body && <h3 className="txt-white">{body}</h3>}
            {cta?.text && cta?.link && (
              <a href={cta?.link} className="cta">
                {cta?.text}
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
