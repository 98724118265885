import { useState, useEffect, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";
import useBreaks from "./hooks/useBreaks";
import useArabic from "./hooks/useArabic";
import useText from "./hooks/useText";
import { Helmet } from "react-helmet";
import locale from "./json/locale.json";

function SiteMenu() {
  const arabic = useArabic();
  const text = useText();
  const location = useLocation();
  const path = location.pathname;

  return (
    <>
      <div className="dropdown">
        <a href={`/${arabic ? "ar" : "en"}/group`} className={path.indexOf("/group") > -1 ? "active" : ""}>
          <span>{text.menu.group}</span>
        </a>
      </div>
      <div className="dropdown">
        <a href={`/${arabic ? "ar" : "en"}/brands`} className={path.indexOf("/brands") > -1 ? "active" : ""}>
          <span>{text.menu.brands}</span>
        </a>
      </div>
      <div className="dropdown">
        <a href={`/${arabic ? "ar" : "en"}/careers`} className={path.indexOf("/careers") > -1 ? "active" : ""}>
          <span>{text.menu.careers}</span>
        </a>
      </div>
      <div className="link">
        <a href={`/${arabic ? "ar" : "en"}/news`} className={path.indexOf("/news") > -1 ? "active" : ""}>
          <span>{text.menu.newsroom}</span>
        </a>
      </div>
      <div className="link">
        <a href={`/${arabic ? "ar" : "en"}/contact`} className={path.indexOf("/contact") > -1 ? "active" : ""}>
          <span>{text.menu.contact}</span>
        </a>
      </div>
    </>
  );
}

function Language() {
  const arabic = useArabic();
  const location = useLocation();
  const path = location.pathname;
  const arLink = path === "/en" ? "/ar" : path.replace("/en/", "/ar/");
  const enLink = path === "/ar" ? "/en" : path.replace("/ar/", "/en/");
  return (
    <div className="language">
      <a href={enLink} className={!arabic ? "active" : ""}>
        En
      </a>
      <div className="divide"></div>
      <a href={arLink} className={arabic ? "active" : ""}>
        Ar
      </a>
    </div>
  );
}

export default function App() {
  const arabic = useArabic();
  const text = useText();
  const mq = useBreaks();

  const location = useLocation();
  const path = location.pathname;

  const dateYear = new Date().getFullYear();

  const [hovered, setHovered] = useState(false);
  const [sub, setSub] = useState("");

  const mainMenu = useRef();

  useEffect(() => {
    if (mainMenu.current) {
      mainMenu.current.querySelectorAll(".dropdown").forEach((el, i) => {
        el.addEventListener("mouseenter", (e) => {
          setHovered(i + 1);
          setSub("");
        });
      });

      mainMenu.current.querySelectorAll("header .link").forEach((el, i) => {
        el.addEventListener("mouseenter", (e) => {
          setHovered(false);
          setSub("");
        });
      });
    }
  }, [mainMenu]);

  const clickSub = (e) => {
    const { id } = e.target;
    setSub(id);
  };

  const [cookie, setCookie] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("consent") === "true") {
      setCookie(false);
    }
  }, []);

  const handleCookie = () => {
    setCookie(false);
    localStorage.setItem("consent", "true");
  };

  const [mobiItem, setMobiItem] = useState("");
  const mobiParent = (e, value) => {
    if (mq.mid) {
      e.preventDefault();
      e.stopPropagation();
      setMobiItem(value);
    }
  };

  const backMain = () => {
    setMobiItem("");
  };

  const backSub = () => {
    setSub("");
  };

  useEffect(() => {
    if (arabic) {
      document.querySelector("html").setAttribute("lang", "ar");
    }
  }, [arabic]);

  function SEOHandler({ title, description }) {
    const location = useLocation();
    const path = location.pathname;

    // Remove language prefix to get the base path
    const basePath = path.replace(/^\/(en|ar)/, "");

    // Construct the full URLs for each language
    const baseUrl = "https://volkswagengroup-me.com";
    const enUrl = `${baseUrl}/en${basePath}`;
    const arUrl = `${baseUrl}/ar${basePath}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* Canonical URL - use the current language version */}
        <link rel="canonical" href={path.startsWith("/ar") ? arUrl : enUrl} />

        {/* Language alternatives */}
        <link rel="alternate" hrefLang="en" href={enUrl} />
        <link rel="alternate" hrefLang="ar" href={arUrl} />

        {/* Default language version */}
        <link rel="alternate" hrefLang="x-default" href={enUrl} />
      </Helmet>
    );
  }

  return (
    <main dir={arabic ? "rtl" : "ltr"}>
      <SEOHandler title={locale.seo[path].title} description={locale.seo[path].description} />
      {!mq.mid && <div className={`header-dim ${hovered ? "active" : ""}`} onMouseEnter={() => setHovered(false)}></div>}
      <header className={`${mq.mid && hovered ? "fixed" : ""}`}>
        <div className="inner container">
          {mq.mid && (
            <div className="burger">
              <div
                onClick={() => {
                  setHovered(!hovered);
                  setMobiItem("");
                  setSub("");
                  if (hovered) {
                    document.querySelector("html").classList.remove("static");
                  } else {
                    document.querySelector("html").classList.add("static");
                  }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24" fill="white">
                  {!hovered ? (
                    <path d="M16 6v1H0V6h16zm0 11v1H0v-1h16zm0-4.5v-1H0v1h16z" />
                  ) : (
                    <path d="M7.4 11.3.7 4.6l-.7.8L6.6 12 0 18.6l.7.7 6.6-6.6 6.6 6.6.7-.7L8.1 12l6.6-6.6-.7-.8-6.6 6.7z" />
                  )}
                </svg>
              </div>
            </div>
          )}
          <div className="nav-left">
            <div className="logo">
              <a href={`${arabic ? "/ar" : "/en"}`}>
                <img src={`${process.env.PUBLIC_URL}/assets/img/logo${arabic ? "-ar" : ""}-white.svg`} alt="Volkswagen Group Middle East Logo" />
              </a>
            </div>
            {!mq.mid && (
              <nav className="main" ref={mainMenu}>
                <div className="inner nav-inner">
                  <SiteMenu />
                </div>
              </nav>
            )}
          </div>
          <Language />
        </div>
      </header>
      <div className={`header-drawer ${hovered ? "active" : ""}`}>
        <div className="container">
          <div className={`menu-panel ${hovered === 1 ? "active" : ""}`}>
            <div className={`main ${mobiItem !== "" ? "mobi-off" : ""}`}>
              <a
                href={`/${arabic ? "ar" : "en"}/group`}
                className={`mobi-parent ${path.indexOf("/group") > -1 ? "active" : ""}`}
                onClick={(e) => mobiParent(e, "group")}
              >
                <span>{text.menu.group}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </a>
            </div>
            <div className={`items ${mobiItem === "group" ? "mobi-active" : ""}`}>
              {mq.mid && (
                <div className="back-btn" onClick={backMain}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                    <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                  </svg>
                  <span>{text.menu.levelup}</span>
                </div>
              )}

              <button className={`${sub === "middle-east" ? "open" : ""}`} id="middle-east" onClick={clickSub}>
                {text.menu.me}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </button>

              <a href={`/${arabic ? "ar" : "en"}/group/strategy`} className={path.indexOf("/group/strategy") > -1 ? "active" : ""}>
                {text.menu.strategy}
              </a>

              <button className={`${sub === "ethics" ? "open" : ""}`} id="ethics" onClick={clickSub}>
                {text.menu.ethics}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </button>

              <a href={`/${arabic ? "ar" : "en"}/group/fleet`} className={path.indexOf("/group/fleet") > -1 ? "active" : ""}>
                {text.menu.fleet}
              </a>
            </div>
            <div className="sub-columns">
              <div className={`sub-items ${sub === "middle-east" ? "active" : ""}`}>
                {mq.mid && (
                  <div className="back-btn" onClick={backSub}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                      <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                    </svg>
                    <span>{text.menu.levelup}</span>
                  </div>
                )}
                <a href={`/${arabic ? "ar" : "en"}/group/about`} className={path.indexOf("/") > -1 ? "active" : ""}>
                  {text.menu.about}
                </a>
                <a href={`/${arabic ? "ar" : "en"}/group/team`} className={path.indexOf("/") > -1 ? "active" : ""}>
                  {text.menu.leadership}
                </a>
              </div>
              <div className={`sub-items ${sub === "ethics" ? "active" : ""}`}>
                {mq.mid && (
                  <div className="back-btn" onClick={backSub}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                      <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                    </svg>
                    <span>{text.menu.levelup}</span>
                  </div>
                )}

                <a href={`/${arabic ? "ar" : "en"}/group/integrity`} className={path.indexOf("/") > -1 ? "active" : ""}>
                  {text.menu.integrity}
                </a>
                <a href={`/${arabic ? "ar" : "en"}/group/conduct`} className={path.indexOf("/") > -1 ? "active" : ""}>
                  {text.menu.code}
                </a>
                <a href={`/${arabic ? "ar" : "en"}/group/whistleblower`} className={path.indexOf("/") > -1 ? "active" : ""}>
                  {text.menu.whistleblower}
                </a>
                <a href={`/${arabic ? "ar" : "en"}/group/human-rights`} className={path.indexOf("/") > -1 ? "active" : ""}>
                  {text.menu.business}
                </a>
              </div>
            </div>
          </div>
          <div className={`menu-panel ${hovered === 2 ? "active" : ""}`}>
            <div className={`main ${mobiItem !== "" ? "mobi-off" : ""}`}>
              <a
                href={`/${arabic ? "ar" : "en"}/brands`}
                className={`mobi-parent ${path.indexOf("/brands") > -1 ? "active" : ""}`}
                onClick={(e) => mobiParent(e, "brands")}
              >
                <span>{text.menu.brands}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </a>
            </div>
            <div className={`items ${mobiItem === "brands" ? "mobi-active" : ""}`}>
              {mq.mid && (
                <div className="back-btn" onClick={backMain}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                    <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                  </svg>
                  <span>{text.menu.levelup}</span>
                </div>
              )}
              <a href="https://www.volkswagen-me.com/" target="_blank" rel="noreferrer" className={path.indexOf("/group/vw") > -1 ? "active" : ""}>
                Volkswagen
              </a>
              <a href="https://audi-me.com/" target="_blank" rel="noreferrer" className={path.indexOf("/group/audi") > -1 ? "active" : ""}>
                Audi
              </a>
              <a href="https://www.skoda-me.com/" target="_blank" rel="noreferrer" className={path.indexOf("/group/skoda") > -1 ? "active" : ""}>
                Škoda
              </a>
              <button className={`${sub === "bentley" ? "open" : ""}`} id="bentley" onClick={clickSub}>
                Bentley
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </button>
              <button className={`${sub === "lambo" ? "open" : ""}`} id="lambo" onClick={clickSub}>
                Lamborghini
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </button>
            </div>
            <div className="sub-columns">
              <div className={`sub-items ${sub === "bentley" ? "active" : ""}`}>
                {mq.mid && (
                  <div className="back-btn" onClick={backSub}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                      <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                    </svg>
                    <span>{text.menu.levelup}</span>
                  </div>
                )}

                <a href="https://abudhabi.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.abudhabi}
                </a>
                <a href="https://bahrain.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.bahrain}
                </a>
                <a href="https://dubai.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.dubai}
                </a>
                <a href="https://kuwait.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.kuwait}
                </a>
                <a href="https://riyadh.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.saudiarabia}
                </a>
                <a href="https://muscat.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.oman}
                </a>
                <a href="https://doha.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.qatar}
                </a>
                <a href="https://beirut.bentleymotors.com/meia/en/home" target="_blank" rel="noreferrer">
                  {text.menu.lebanon}
                </a>
              </div>
              <div className={`sub-items ${sub === "lambo" ? "active" : ""}`}>
                {mq.mid && (
                  <div className="back-btn" onClick={backSub}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                      <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                    </svg>
                    <span>{text.menu.levelup}</span>
                  </div>
                )}

                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-abu-dhabi#showroom" target="_blank" rel="noreferrer">
                  {text.menu.abudhabi}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-bahrain#showroom" target="_blank" rel="noreferrer">
                  {text.menu.bahrain}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-dubai#showroom" target="_blank" rel="noreferrer">
                  {text.menu.dubai}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-kuwait#showroom" target="_blank" rel="noreferrer">
                  {text.menu.kuwait}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-riyadh#showroom" target="_blank" rel="noreferrer">
                  {text.menu.riyadh}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-jeddah#showroom" target="_blank" rel="noreferrer">
                  {text.menu.jeddah}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-muscat#showroom" target="_blank" rel="noreferrer">
                  {text.menu.oman}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-doha#showroom" target="_blank" rel="noreferrer">
                  {text.menu.qatar}
                </a>
                <a href="https://www.lamborghini.com/en-en/dealerships/lamborghini-beirut#" target="_blank" rel="noreferrer">
                  {text.menu.lebanon}
                </a>
              </div>
            </div>
          </div>
          <div className={`menu-panel ${hovered === 3 ? "active" : ""}`}>
            <div className={`main ${mobiItem !== "" ? "mobi-off" : ""}`}>
              <a href={`/${arabic ? "ar" : "en"}/careers`} className={`mobi-parent`} onClick={(e) => mobiParent(e, "careers")}>
                <span>{text.menu.careers}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.9 32" fill="#fff">
                  <path d="m0 25.7.8.6 8-10c.1-.2.1-.4.1-.5l-.1-.1-8-10-.8.6L7.8 16 0 25.7z" />
                </svg>
              </a>
            </div>
            <div className={`items ${mobiItem === "careers" ? "mobi-active" : ""}`}>
              {mq.mid && (
                <div className="back-btn" onClick={backMain}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.6 24">
                    <path d="M.4 11.5c-.2.1-.4.3-.4.5 0 .3.2.5.5.5h14.3l-3.1 3.1.7.7 4-4 .1-.1c.1-.2.1-.5-.1-.6l-4-4-.7.7 3.1 3.1-14.4.1z" />
                  </svg>
                  <span>{text.menu.levelup}</span>
                </div>
              )}

              <a href={`/${arabic ? "ar" : "en"}/careers/working-with-us`} className={path.indexOf("/careers/working-with-us") > -1 ? "active" : ""}>
                {text.menu.working}
              </a>
              <a href="https://careers.volkswagen-me.info/index.php/job-listings" target="_blank" rel="noreferrer">
                {text.menu.jobs}
              </a>
            </div>
          </div>
          {mq.mid && (
            <>
              <div className="menu-panel">
                <div className={`main ${mobiItem !== "" ? "mobi-off" : ""}`}>
                  <a href={`/${arabic ? "ar" : "en"}/news`} className={path.indexOf("/news") > -1 ? "active" : ""}>
                    <span>{text.menu.newsroom}</span>
                  </a>
                </div>
              </div>
              <div className="menu-panel">
                <div className={`main ${mobiItem !== "" ? "mobi-off" : ""}`}>
                  <a href={`/${arabic ? "ar" : "en"}/contact`} className={path.indexOf("/contact") > -1 ? "active" : ""}>
                    <span>{text.menu.contact}</span>
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <section className="content">
        <Outlet />
      </section>

      <footer>
        <div className="inner container">
          <div className="footer-col">
            <div className="link copyright">
              <a href={`/${arabic ? "/ar" : "/en"}`} className="copyright">
                {arabic ? `© ${dateYear} Volkswagen Group Middle East` : `© ${dateYear} Volkswagen Group Middle East`}
              </a>
            </div>
            <SiteMenu />
            <div className="link">
              <a href="https://www.volkswagen-group.com/en/privacy-policy-15677" target="_blank" rel="noreferrer">
                {text.menu.policy}
              </a>
            </div>
          </div>
          <div className="footer-col">
            <Language />
            <div className="social">
              <a href="https://www.linkedin.com/company/audi-volkswagen-middle-east/" target="_blank" rel="noreferrer">
                <FaLinkedin size="24px" />
              </a>
            </div>
          </div>
        </div>
      </footer>
      {cookie && (
        <div className="cookies">
          <button className="close" onClick={handleCookie}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M5.902 17.1L17.098 5.9M5.9 5.902l11.2 11.196" fill="none" stroke="currentColor" strokeMiterlimit="10" />
            </svg>
          </button>
          <p>{text.menu.cookies}</p>
        </div>
      )}
    </main>
  );
}
