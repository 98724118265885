import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import useArabic from "../../hooks/useArabic";
import useText from "../../hooks/useText";
import parse from "html-react-parser";

export default function Page() {
  const arabic = useArabic();
  const text = useText();
  return (
    <div className="page">
      {/* banner */}
      <div className="ui-banner">
        <div className="media">
          <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_darkgreen.mp4`} autoPlay muted loop playsInline />
        </div>
        <div className="ui-inner">
          <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>
            {parse(text.integrity_banner_headline)}
          </GroupuiHeadline>
          <GroupuiText>{parse(text.integrity_banner_text)}</GroupuiText>
        </div>
      </div>
      {/* dual teaser */}
      <div className="ui-content-teaser">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.preface_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/gradient2.jpg`} alt="" />
              </div>
            </div>
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.our_approach_headline}
              </GroupuiHeadline>
              <GroupuiText>{parse(text.our_approach_text)}</GroupuiText>
              <a href={`/${arabic ? "ar" : "en"}/group/conduct`} className="cta">
                {text.code_of_conduct_cta}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* dual teaser */}
      <div className="ui-content-teaser ui-deep-blue-bg">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.compliance_management_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.compliance_management_subheadline}
              </GroupuiHeadline>
              <GroupuiText>{parse(text.compliance_management_text)}</GroupuiText>
            </div>
          </div>
        </div>
      </div>
      {/* dual teaser */}
      <div className="ui-content-teaser">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.risk_management_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.risk_management_subheadline}
              </GroupuiHeadline>
              <GroupuiText>{parse(text.risk_management_text)}</GroupuiText>
            </div>
          </div>
        </div>
      </div>
      {/* dual teaser */}
      <div className="ui-content-teaser ui-deep-blue-bg">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.integrity_management_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.integrity_management_subheadline}
              </GroupuiHeadline>
              <GroupuiText>{parse(text.integrity_management_fulltext)}</GroupuiText>
            </div>
          </div>
        </div>
      </div>
      <div className="ui-content-teaser">
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.whistleblower_system_headline}
          </GroupuiHeadline>
          <div className="ui-columns">
            <div className="ui-column">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/editorial/gradient3.jpg`} alt="" />
              </div>
            </div>
            <div className="ui-column">
              <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                {text.integrity_at_its_core_headline}
              </GroupuiHeadline>
              <GroupuiText>{parse(text.whistleblower_system_text)}</GroupuiText>
              <a href={`/${arabic ? "ar" : "en"}/group/whistleblower`} className="cta">
                {text.learn_more_cta}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
