import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import useArabic from "../hooks/useArabic";
import useText from "../hooks/useText";

export default function Component() {
  const arabic = useArabic();
  const text = useText();

  return (
    <div className="ui-content-teaser ui-brand-grid">
      <div className="ui-inner">
        <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
          {text.our_brands}
        </GroupuiHeadline>
        <div className="ui-columns">
          <div className="ui-column">
            <div className="item">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-vw.jpg?123`} alt="brand" />
              </div>
              <div className="info">
                <div className="inner">
                  <div>
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/vw.png`} alt=""/> */}
                    <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                      Volkswagen
                    </GroupuiHeadline>
                    <GroupuiText>{text.das_auto}</GroupuiText>
                  </div>
                  <div>
                    <a href={`/${arabic ? "ar" : "en"}/brands?scroll=vw`} className="cta">
                      {text.explore_brand}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui-column">
            <div className="item">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-audi.jpg?123`} alt="brand" />
              </div>
              <div className="info">
                <div className="inner">
                  <div className="audi">
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/audi.png`} alt=""/> */}
                    <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                      Audi
                    </GroupuiHeadline>
                    <GroupuiText>{text.future_is_an_attitude}</GroupuiText>
                  </div>
                  <div>
                    <a href={`/${arabic ? "ar" : "en"}/brands?scroll=audi`} className="cta">
                      {text.explore_brand}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui-column">
            <div className="item">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-skoda.jpg?123`} alt="brand" />
              </div>
              <div className="info">
                <div className="inner">
                  <div>
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/skoda.png`} alt=""/> */}
                    <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                      Škoda
                    </GroupuiHeadline>
                    <GroupuiText>{text.lets_explore}</GroupuiText>
                  </div>
                  <div>
                    <a href={`/${arabic ? "ar" : "en"}/brands?scroll=skoda`} className="cta">
                      {text.explore_brand}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui-column">
            <div className="item">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-bentley.jpg?123`} alt="brand" />
              </div>
              <div className="info">
                <div className="inner">
                  <div>
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/bentley.png`} alt=""/> */}
                    <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                      Bentley
                    </GroupuiHeadline>
                    <GroupuiText>{text.extraordinary_journeys}</GroupuiText>
                  </div>
                  <div>
                    <a href={`/${arabic ? "ar" : "en"}/brands?scroll=bentley`} className="cta">
                      {text.explore_brand}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ui-column">
            <div className="item">
              <div className="media">
                <img src={`${process.env.PUBLIC_URL}/assets/img/brands/grid-lambo.jpg?123`} alt="brand" />
              </div>
              <div className="info">
                <div className="inner">
                  <div>
                    {/* <img src={`${process.env.PUBLIC_URL}/assets/img/logo/lamborghini.png`} alt=""/> */}
                    <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                      Lamborghini
                    </GroupuiHeadline>
                    <GroupuiText>{text.driving_humans_beyond}</GroupuiText>
                  </div>
                  <div>
                    <a href={`/${arabic ? "ar" : "en"}/brands?scroll=lamborghini`} className="cta">
                      {text.explore_brand}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
