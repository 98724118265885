import LatestNews from "../components/LatestNews";
import BrandGrid from "../components/BrandGrid";
import useArabic from "../hooks/useArabic";
import useText from "../hooks/useText";
import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";

export default function Page() {
  const arabic = useArabic();
  const text = useText();

  return (
    <div className="page">
      <div className="ui-banner">
        <div className="media">
          <video src={`${process.env.PUBLIC_URL}/assets/video/home.mp4`} autoPlay muted loop playsInline />
        </div>
        <div className="ui-inner">
          <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>
            {text.powering_innovation}
          </GroupuiHeadline>
          <GroupuiText>{text.welcome}</GroupuiText>
          <a href={`/${arabic ? "ar" : "en"}/group`} className="cta">
            {text.discover_more}
          </a>
        </div>
      </div>
      <section id="start">
        <LatestNews />
        <BrandGrid />
        <div className="ui-banner">
          <div className="media flip">
            <img src={`${process.env.PUBLIC_URL}/assets/img/fleet-banner.jpg`} alt="" />
          </div>
          <div className="ui-inner">
            <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>
              {text.volkswagen_group} <br />
              <span className="ui-neon-text">{text.fleet_solutions}</span>
            </GroupuiHeadline>
            <GroupuiText>{text.fleet_discover}</GroupuiText>
            <a href={`/${arabic ? "ar" : "en"}/group/fleet`} className="cta">
              {text.discover_more}
            </a>
          </div>
        </div>
        <div className="ui-content-teaser">
          <div className="ui-inner">
            <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
              {text.quick_links}
            </GroupuiHeadline>
            <div className="ui-columns">
              <div className="ui-column">
                <div className="media">
                  <img src={`${process.env.PUBLIC_URL}/assets/img/grid-abstract1.jpg`} alt="" />
                  <a href={`/${arabic ? "ar" : "en"}/careers`} className="overlay">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={false}>
                      {text.careers}
                    </GroupuiHeadline>
                  </a>
                </div>
              </div>
              <div className="ui-column">
                <div className="media">
                  <img src={`${process.env.PUBLIC_URL}/assets/img/grid-abstract2.jpg`} alt="" />
                  <a href={`/${arabic ? "ar" : "en"}/contact`} className="overlay">
                    <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={false}>
                      {text.contact}
                    </GroupuiHeadline>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
