import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import useArabic from "../hooks/useArabic";
import useText from "../hooks/useText";
import careers from "../json/careers.json";

export default function Page() {
  const text = useText();
  const arabic = useArabic();
  const list = Object.values(careers);

  return (
    <div className="page">
      <div className="ui-banner">
        <div className="media">
          <video src={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_darkgreen.mp4`} autoPlay muted loop playsInline />
        </div>
        <div className="ui-inner">
          <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>
            {text.drive_your_career}
          </GroupuiHeadline>
          <GroupuiText>{text.welcome_careers_page}</GroupuiText>
        </div>
      </div>

      <div className={`ui-content-teaser no-margin`}>
        <div className="ui-inner">
          <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
            {text.working_at_vwgme}
          </GroupuiHeadline>
        </div>
      </div>

      {list.map((item, k) => (
        <div key={k} className={`ui-content-teaser no-gap-top`}>
          <div className="ui-inner">
            <div className="ui-columns">
              <div className="ui-column">
                <div className="media">
                  <img src={`${process.env.PUBLIC_URL}${item.img.thumb}`} alt="" />
                </div>
              </div>
              <div className="ui-column">
                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                  {item.title[arabic ? "ar" : "en"]}
                </GroupuiHeadline>
                <GroupuiText>{item.lead[arabic ? "ar" : "en"]}</GroupuiText>
                <a href={item.cta[0].link[arabic ? "ar" : "en"]} className="cta">
                  {item.cta[0].text[arabic ? "ar" : "en"]}
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
