import { GroupuiHeadline, GroupuiText } from "@group-ui/group-ui-react";
import useBreaks from "../../hooks/useBreaks";
import useArabic from "../../hooks/useArabic";
import useText from "../../hooks/useText";

export default function Page() {
  const arabic = useArabic();
  const text = useText();
  const mq = useBreaks();

  const team = [
    {
      top: {
        text: {
          ar: "الرئيس التنفيذي",
          en: "Chief Executive Officer",
        },
      },
      img: {
        thumb: "/assets/img/team/benoit.jpg",
      },
      title: text.team_title1,
      lead: text.team_body1,
    },
    {
      top: {
        text: {
          ar: "المدير المالي",
          en: "Chief Financial Officer",
        },
      },
      img: {
        thumb: "/assets/img/team/jeff.jpg",
      },
      title: text.team_title2,
      lead: text.team_body2,
    },
    {
      top: {
        text: {
          ar: "مدير الموارد البشرية للمجموعة",
          en: "Group HR Director",
        },
      },
      img: {
        thumb: "/assets/img/team/rachel.jpg",
      },
      title: text.team_title3,
      lead: text.team_body3,
    },
    {
      top: {
        text: {
          ar: "Audi، المدير العام",
          en: "Audi, Managing Director",
        },
      },
      img: {
        thumb: "/assets/img/team/rene.jpg",
      },
      title: text.team_title4,
      lead: text.team_body4,
    },
    {
      top: {
        text: {
          ar: "فولكس واجن، المدير العام",
          en: "VW, Managing Director",
        },
      },
      img: {
        thumb: "/assets/img/team/matthias.jpg",
      },
      title: text.team_title5,
      lead: text.team_body5,
    },
    {
      top: {
        text: {
          ar: "شكودا، المدير العام",
          en: "Škoda, Managing Director",
        },
      },
      img: {
        thumb: "/assets/img/team/lukas.jpg",
      },
      title: text.team_title6,
      lead: text.team_body6,
    },
    {
      top: {
        text: {
          ar: "مدير خدمات ما بعد البيع للمجموعة",
          en: "Group After-sales Director",
        },
      },
      img: {
        thumb: "/assets/img/team/egemen.jpg",
      },
      title: text.team_title7,
      lead: text.team_body7,
    },
  ];

  return (
    <div className="page">
      <div className="ui-banner">
        <div className="media">
          <img src={`${process.env.PUBLIC_URL}/assets/img/team/${mq.tablet ? "mobi" : "banner"}.jpg`} alt="" />
        </div>
        <div className="ui-inner">
          <GroupuiHeadline heading="h2" fontVariant="TheGroupHEAD-Light" marketing={true}>
            <span className="ui-neon-text">{text.leadership_team}</span>
          </GroupuiHeadline>
          <GroupuiText>{text.leadership_team_body}</GroupuiText>
        </div>
      </div>

      <div className={`ui-content-teaser no-margin no-gap-bottom`}>
        <div className="ui-inner">
          {/* <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>Meet our Senior Leadership Team at Volkswagen Group Middle East</GroupuiHeadline> */}
        </div>
      </div>

      {team.map((item, k) => (
        <div key={k} className={`ui-content-teaser no-gap-top`}>
          <div className="ui-inner">
            <div className="ui-columns">
              <div className="ui-column">
                <div className="media boxed align-top">
                  <img src={`${process.env.PUBLIC_URL}${item.img.thumb}`} alt="" />
                </div>
              </div>
              <div className="ui-column">
                <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                  {item.title}
                </GroupuiHeadline>
                <GroupuiHeadline heading="h6" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                  {item.top.text[arabic ? "ar" : "en"]}
                </GroupuiHeadline>
                <GroupuiText>{item.lead}</GroupuiText>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
