// form page

import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  styles: {
    global: (props) => ({
      body: {
        bg: "#f2f2f2",
      },
    }),
  },
  fonts: {
    heading: `'Volkswagen Group Font', 'Arabic Font',  sans-serif`,
    body: `'Volkswagen Group Font', 'Arabic Font',  sans-serif`,
  },
  colors: {
    vw: "#0092cc",
    brand: {
      black: "#141414",
      50: "#930321",
      100: "#A70325",
      200: "#BA042A",
      300: "#CE042E",
      400: "#E10533",
      500: "#48545a",
      600: "#F61947",
      700: "#fd2c4e",
      800: "#F74167",
      900: "#F85577",
    },
  },
  sizes: {
    middesktop: "1366px",
    desktop: "1440px",
    max: "1920px",
  },
  components: {
    Button: {
      baseStyle: {
        color: "white",
        bg: "var(--blue)",
        fontWeight: "bold",
        borderRadius: "0",
        py: 6,
        _hover: {
          bg: "var(--blue-dark)",
          textDecoration: "none",
        },
      },
      variants: {
        base: {},
        secondary: {
          color: "white",
          bg: "var(--blue-dark)",
          fontWeight: "bold",
          borderRadius: "0",
          py: 6,
          _hover: {
            bg: "var(--blue-darker)",
            textDecoration: "none",
          },
        },
      },
      defaultProps: {
        variant: "base",
      },
    },
  },
});
