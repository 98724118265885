import Slider from "react-slick";
import "/node_modules/slick-carousel/slick/slick.css";
import "/node_modules/slick-carousel/slick/slick-theme.css";
import useArabic from "../hooks/useArabic";
import useText from "../hooks/useText";
import { GroupuiHeadline } from "@group-ui/group-ui-react";

import news from "../json/news.json";

export default function Component() {
  const arabic = useArabic();
  const text = useText();

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="ui-slider">
      <div className="ui-intro">
        <GroupuiHeadline heading="h1" fontVariant="TheGroupHEAD-Light" marketing={false}>
          {text.latest_news}
        </GroupuiHeadline>
      </div>
      <div className="slider-inner">
        <div className="ui-slider-content">
          <Slider {...settings}>
            {news.map((item, k) => (
              <div className="item" key={k}>
                <div className="media">
                  <img src={`${process.env.PUBLIC_URL}${item.img.thumb}`} alt="news article" />
                </div>
                <div className="info">
                  <div className="inner">
                    <div>
                      <div className="ui-chip">{item.brand === "Global" ? text.global_news : text.regional_news}</div>
                      <GroupuiHeadline heading="h3" fontVariant="TheGroupHEAD-Bold" marketing={false}>
                        {item.title[arabic ? "ar" : "en"]}
                      </GroupuiHeadline>
                    </div>
                    <div>
                      <a href={item.cta[0].link} className="cta">
                        {text.read_article}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
