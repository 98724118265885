import useText from "../hooks/useText";
import Banner from "../components/Banner";

export default function Page() {
  const text = useText();
  return (
    <div className="page error">
      <Banner
        video={`${process.env.PUBLIC_URL}/assets/video/VWG_Layers_darkgreen.mp4`}
        title={text.page_not_exist}
        body={text.page_not_found_message}
        cta={{ text: text.return_to_site, link: "/" }}
        dim
        full
      />
    </div>
  );
}
